.content-list{
    list-style: none;
    margin: 0;
    padding: 0;
    color: $dark;
    font-size: 0.875rem;
    li{
       margin-bottom:1rem;
       font-family: $headings-font-family;
       text-transform: uppercase;
       display: block;
    }
    li.important{
        background-color: $menu-bg;
        color:$white;
        a{
            background-color: $menu-bg;
            color:$white;

        }
        .icon-wrap{
            color:$white;
        }
    }
    li > a{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: stretch;
        flex-flow: row nowrap;
        justify-content: flex-start;
        transition: 0.3s;
        background-color: $white;
        color: $dark;
        font-weight: 400;
    }
    li > .flex-wrap{
        display: flex;
        flex-direction: row;align-items: center;
        align-content: stretch;
        flex-flow: row nowrap !important;
        justify-content: flex-start;
        background-color: $white;;
    }
    li > a:hover{
        background-color:$light-grey;
        text-decoration:none;
    }
    li{
        img{
            margin-right:1rem;
        }
    }
    .content-label{
        margin-right:30px;
    }
    .icon-wrap{
        position: absolute;
        right:10px;
        font-size:1.25rem;
        color:$primary;
    }

    .content-icon{
        width:90px;
        height:55px;
        display: block;
        background-color:$semi-dark;
        margin-right:1rem;
        font-size:2.25rem;
        line-height:55px;
        text-align:center;
    }
}
.content-list.row{
    margin-left:-10px;
    margin-right: -10px;;

}

.content-list-no-img{
    li{
        a{
            min-height:40px;
            padding-left:1rem;
        }
    }
}