.sort-list{
    margin:0;
    padding:0;
    li{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: .75rem;
        .num{
            display: flex;
            min-width:30px;
            height:30px;
            line-height: 30px;
            text-align:center;
            display: inline-block;
            margin-right: .75rem;
            background-color:$primary;
            color:$white;
            border-radius: 50%;
            margin-top:6px;
        }
        .sort-item{
            position: relative;
            padding: .75rem;
            flex-grow:1;
            display: flex;
            border-radius: $border-radius;
            background-color: $light-grey;
            color:$dark;
            .sort-handle{
                position: absolute;
                right:.74rem;
            }
        }
        .sort-label{
            margin-right:10px;
        }
        
    }
}
.dndPlaceholder {

    min-height: 45px;
    background-color: $light-grey;
    border-radius: $border-radius;
    opacity:.5;
    margin-left:30px;
  }
  .dndDraggingSource{
      display:none !important;
  }