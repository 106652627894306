.goal-filter {
  font-family: $headings-font-family;
  color: $dark;
  text-transform: uppercase;
  .status {
    margin: 0 0.5rem;
    padding:2px 5px;
  }
  .status.active{
    span.icon-wrap{
      color:$white !important;
    }
    color:$white;
    background-color: $primary;
  }
}
