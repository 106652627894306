.alert-semi-dark {
  background-color: $semi-dark;
  color: $white;
  h3 {
    color: $white;
    font-size: 1.125rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
  }
  a {
    color: $white;
  }
}
.info-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  li {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    position: relative;
    opacity: 0;
    animation: fade-in 1500ms ease forwards;
    .icon-wrap {
      position: absolute;
      left: 0;
      top: 8px;
      font-size: 1rem;
      color: $primary;
    }
    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 1500ms;
    }
    &:nth-child(3) {
      animation-delay: 3000ms;
    }
    &:nth-child(4) {
      animation-delay: 4500ms;
    }
    &:nth-child(5) {
      animation-delay: 6000ms;
    }
    &:nth-child(6) {
      animation-delay: 7500ms;
    }
    &:nth-child(7) {
      animation-delay: 9000ms;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
