.btn {
  font-family: $headings-font-family;
  font-weight: normal;
  text-transform: uppercase;
}
.btn-outline-primary {
  border-width: 2px;
}

@each $color, $value in $theme-colors {
  .btn-transparent-#{$color} {
    @include button-variant(rgba($value, 0.8), rgba($value, 0.8));
  }
}
