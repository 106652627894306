.login-logo {
  text-align: center;
  margin: 4% 0;
}
.login {
  .form-control,
  .input-group-text {
    background-color: $menu-bg;
    color: $white;
    border: 1px solid $menu-bg;
  }
  ::placeholder {
    color: $white;
  }
  .input-group-text {
    margin-right: 2px;
    display: inline-block;
  }
}
 
.about-login {
  font-weight: 300;

  .sm-title {
    font-size: 1.09375rem;
    font-weight: 400;
    color: $white;
  }
  p {
    margin-bottom: 1.5rem;
  }
  ol {
    text-align:left;
  margin: 0;
  padding-left: 0.1rem;
  list-style-type: none;
      
    li {
      margin-bottom: 1.5rem;
  margin-left: 2.25rem;
  position: relative;
  counter-increment: customlistcounter;
    }
    li:before {
      content: counter(customlistcounter) " ";
      font-weight: bold;
      float: left;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-weight: bold;
      color: $white;
      text-align: center;
      background-color: $primary;
      margin-left: -1.5rem;
      left: -0.75rem;
      position: absolute;
      line-height: 24px;
    }
  }
  background-color: rgb(0,0,0,0.2);
  padding: 1rem;
  color: $white;
}
