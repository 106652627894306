.navbar-main {
  min-height: 60px;

  .back-link {
    position: absolute;
    top: 12px;
    left: 10px;
    color: $white;
    font-size: 1.125rem;
    line-height: 1.125rem;
    display: inline;
    padding: 0.5rem 1rem 0.5rem 0.9rem;
    border-radius: $border-radius;
    &:hover {
      background-color: rgb(255, 255, 255, 0.1);
    }
    &:active {
      background-color: rgb(255, 255, 255, 0.3) !important;
      transition: 0s;
    }
  }
  .navbar-brand {
    font-family: $headings-font-family;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    top: 10px;
    img {
      height: 42px;
    }
  }
  h1 {
    color: $white;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 60px;
    margin: 0;
    padding: 0;
    position: relative;
    top: -10px;
  }
}
.navbar-desktop .nav-item .nav-link {
  margin: 0 5px;
  text-align: center;
  color: $white;
  border-top: 2px solid transparent;
  .icon-wrap {
    font-size: 1.25rem;
  }
  .nav-label {
    font-family: $headings-font-family;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: block;
  }
}
.navbar-desktop .nav-item.active .nav-link {
  color: $white;
}
.has-sidebar {
  .navbar-desktop {
    visibility: hidden;
  }
}

// media queries
@include media-breakpoint-up(md) {
  .navbar-main .navbar-brand {
    left: 10px;
  }
  .has-sidebar {
    .navbar-main .navbar-brand {
      left: auto;
    }
  }
  .navbar-main {
    height: 60px;
  }
  .navbar-main.navbar-tall {
    height: 100px;
    .navbar-brand {
      img {
        height: 68px;
        margin-top: -15px;
        margin-left: 10px;
      }
    }
  }
}

#user-dropdown-btn {
  margin-top: 3px;
}
.ml-auto.btn-group{
  display:none;
}
// media queries
@include media-breakpoint-down(md) {
  .ml-auto.btn-group{
    display:block;
  }
  .navbar-main {
    .dropdown-menu {
      width: 100vw;
      margin-right: -1rem;
      border: none;
      margin-top: 15px;
      -webkit-box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.75);
box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.75);
border-top: 1px solid #fff;
transition: all 0.4s ease-in-out;
    }

    .dropdown-item {
      padding: 1rem;
      text-transform: uppercase;
      font-family: $headings-font-family;
      font-size: 1rem;
    }
  }
}



