.slide {
  position: relative;
  img {
    border: 2px solid $light-grey;
  }
  .slide-title {
    font-size: 0.75rem;
  }
  .slide-num {
    color: $white;
    background-color: $primary;
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: $headings-font-family;
    font-size: 0.625rem;
    text-align: center;
    width: 15px;
    height: 15px;
    line-height: 16px;
  }
}
.slide-prevnext {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.slide-indicators {
  font-size: 0.75rem;
  line-height: 1.5rem;
}
