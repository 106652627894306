.sidebar{
  display:none;
}
// media queries
@include media-breakpoint-up(lg) {
  .sidebar{
    display:block;
    position: static;
    z-index: 3;
    min-height: 100vh;
    width: $sidebar-width - 20;
    .logo{
      padding:1rem 0 ;
      text-align: center;
      img{
        display:inline-block;
        height:68px;
      }
    }
  }
  .sidebar-container{
    width:$sidebar-width;
    position: absolute;
    top:0;
    left:0;
  }
  .sidebar-container-off{
    width:0;
  }
  .has-sidebar{
    margin-left:$sidebar-width;
  }
  .sidebar{
    margin:0 2rem 0 0;
    background:$white;
  
    .navbar-desktop .nav-item .nav-link {
      color: $dark;
      border-top: none;
      margin:0;
      padding:0 0.5rem;
      font-size:1.5rem;
    }
    .navbar-desktop .nav-item.active .nav-link {
      color: $primary;
    }
  
    .navbar-desktop .nav-item .nav-link .nav-label{
      display:none;
    }
  
    .sidebar-subnav{
      margin:.5rem;
      .card{
        margin-bottom:.5rem;
      }
      .card-header{
        background-color:$light;
        padding: 0.25rem 0.5rem;
        .btn-link{
          color:$dark;
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }

  }
}




.sidebar-nav{
  margin:.5rem;
  .nav-icon{
    font-size:1.125rem;
    border-radius:$border-radius;
    color:$dark;
    display:inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-left:.5rem;
  }

  .nav-link{
    font-family: $headings-font-family;
    background-color:$light;
    font-size:1rem;
    padding-left:0;
    display: flex;
    padding: 0rem;
    margin-bottom: 0.75rem;
    color:$dark;
    text-transform: uppercase;
    font-weight: 400;
  }
  .nav-link:hover{
    background-color:$primary;
    color:$white;
    .nav-icon{
      color:$white;
    }
  }

  .nav-link-title {
    line-height: 40px;
    height: 40px;
    flex: 1;
    border-radius:$border-radius;
    display:inline-block;
    padding: 1px 10px;
  }


  .active{
    background-color:$primary;
    color:$white;
    .nav-icon{
      background-color:$primary;
      color:$white;
    }
  }
  ul ul{
    display:none;
    margin: 0rem 0 1.5rem 0rem;
    padding:0;
    .nav-item{
      border-bottom:1px solid $light-grey;
      background-color: $white;
      .nav-link{
        background-color: $white;
        font-size:0.875rem;
        color:$dark;
        padding:.6rem 0.5rem ;
        margin-bottom: 0rem;
        transition: all 250ms ease-in-out;
        &:hover {
          background-color: $light;
        }
        .icon-wrap{
          color:$primary;
          margin-right:.5rem;
        }
      }
      .active{
        color:$primary;
      }
    }
  }
  .active ~ ul{
    display:block;
  }
}