// Fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@300;400;500&display=swap");

@import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrap-variables";
@import "../node_modules/bootstrap/scss/bootstrap";

$sidebar-width: 270px;
@import "styles/general";
@import "styles/helpers";
@import "styles/login";
@import "styles/navbar";
@import "styles/subnav";
@import "styles/footer-nav";
@import "styles/side-nav";
@import "styles/typography";
@import "styles/content-title";
@import "styles/buttons";
@import "styles/cards";
@import "styles/tabs";
@import "styles/alerts";
@import "styles/evaluation";
@import "styles/dashboard";
@import "styles/resources";
@import "styles/goals";
@import "styles/progress-circle";
@import "styles/progress-table";
@import "styles/record-table";
@import "styles/task-list";
@import "styles/extra-list";
@import "styles/slide";
@import "styles/exercise-list";
@import "styles/custom-slider";
@import "styles/date-picker";
@import "styles/jumbotron";
@import "styles/forms";
@import "styles/sidebar";
@import "styles/footer";
@import "styles/loading-spinner";
@import "styles/speech-bubble";
@import "styles/media-queries";
@import "styles/content-list";
@import "styles/chat";
@import "styles/sort-list";
@import "styles/action-plan-list";
@import "styles/add-to-homescreen";
@import "styles/cookie-consent";

// @import '../node_modules/@sweetalert2/themes/minimal/minimal.scss';