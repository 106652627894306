.widget-icons {
  position: relative;
  margin-bottom: 0.5rem;
  line-height: 2.25rem;
}
.card-widget {
  text-align: center;
  .circle {
    margin-left: 5px;
    position: relative;
    top: 5px;
  }
  td .circle {
    top: 0px;
    margin-left: 0px;
    line-height: 21px;
  }
  .widget-icon {
    margin-right: 5px;
  }
}
.circle {
  border-radius: 50%;
  display: inline-block;
  width: 58px;
  height: 58px;
  color: $white;
  font-size: 2.25rem;
  line-height: 58px;
  font-family: $headings-font-family;
  text-align: center;
}
.widget-icon {
  font-size: 60px;
  color: $dark;
  vertical-align: middle;
}
.widget-updated {
  font-size: 0.625rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.goal-status-container {
  display: inline-block;
  line-height: 1rem;
  margin: auto 0.5rem;
  position: relative;
  top: 15px;
  .goal-status {
    .icon-wrap {
      font-size: 2.25rem;
      color: $primary;
    }
    span.total {
      color: $dark;
      font-size: 1.875rem;
      font-family: $headings-font-family;
    }
  }
  .goal-status-label {
    font-size: 0.625rem;
    font-weight: 400;
  }
}

// tasks

.db-task {
  padding: 2rem 4rem;
  text-align: center;
  .db-task-num {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: $semi-dark;
    color: $white;
    line-height: 36px;
    text-align: center;
    margin: 0.5rem auto;
    font-size: 1.5rem;
    font-family: $headings-font-family;
  }

  .db-task-title {
    font-size: 0.875;
    text-transform: uppercase;
    color: $dark;
    font-family: $headings-font-family;
    font-weight: 400;
    margin: 0.5rem 1rem 1rem 1rem;
  }
  .db-task-text {
    margin: 1rem 0 1.25rem 0;
    text-align: left;
    font-size: 0.75rem;
  }
}
.db-task-prev {
  position: absolute;
  left: 1.75rem;
  top: 40%;
  font-size: 2.25rem;
  color: $semi-dark;
  z-index: 10;
  cursor: pointer;
}
.db-task-next {
  position: absolute;
  right: 1.75rem;
  top: 40%;
  font-size: 2.25rem;
  color: $semi-dark;
  z-index: 10;
  cursor: pointer;
}

ul.task-list-alt li a .task-message {
  color: $body-color;
}

// media queries
@include media-breakpoint-down(sm) {
  .db-task {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  .db-task-prev,
  .db-task-next {
    top: 267px;
  }
}

.db-date {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: $light-grey;
  display: block;
  min-width: 30px;
  min-height: 36px;
  text-align: center;
  font-family: $headings-font-family;
  color: $dark;
  .day {
    display: block;
    font-size: 0.75rem;
    text-transform: uppercase;
    line-height: 1rem;
  }
  .num {
    display: block;
    font-size: 1.25rem;
    line-height: 1rem;
  }
}

table.db-table {
  margin-bottom:1rem;
  th {
    color: #666;
    padding: 0;
  }
  td{
    padding:0;
  }
  td.db-table-cell{
    font-size:0.75rem;
    vertical-align: top;
  }
}
table.db-table-workouts{
  width:100px;
  height:95px;
  margin:0 auto;
}
table.db-table-logins{
  width:275px;
  margin:0 auto;
}
.login-message{
  font-size:0.75rem;
}