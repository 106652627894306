.evaluation-container {
  text-align: center;
  padding: 2rem;
  h3 {
    font-family: $font-family-base;
    text-transform: none;
  }
  .circle {
    width: 75px;
    height: 75px;
    line-height: 75px;
    font-size: 3rem;
    transition: all 0.5s linear;
  }



  .radio-group {
    border-top: 1px solid $white;
    .radio {
      border-bottom: 1px solid $white;
    }
    input[type="radio"] {
      opacity: 0;
      width: 0px;
      + label {
        display: block;
        padding: 1rem 1rem 1rem 3rem;
        transition: 0.3s;
        .icon-wrap {
          font-size: 1.5rem;
          line-height: 1.5rem;
          position: absolute;
          left: 5px;
        }
        .icon-wrap .selected {
            display:none;
        }
        .icon-wrap .unselected {
            display:inline-block;
        }
      }

      &:checked {
        + label {
            .icon-wrap .selected {
                display:inline-block;
              }
              .icon-wrap .unselected {
                display:none;
              }
          // color: $white;
          // background: $primary;
        }
      }
      &:focus {
        + label {
          background-color: #FFFA8D;
          color: $dark;
        }
      }
      &:hover {
        + label {
          background-color: $light;
          color: $dark;
        }
      }
    }
  
  }

  .checkbox-container {
    input[type="checkbox"] {
      &:focus {
        + label {
          background-color: #FFFA8D;
          color: $dark;
          box-shadow: 0 0px 0px rgb(0,0,0,0);
        }
      }
    }
  }
  .form-check{
    padding-left:0;
    position: relative;
    border-bottom: 1px solid #CDD3E0;
  }
}

.move-slider {
  font-size: 0.625rem;
  margin: 1.5rem 0;
}
