.task-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  li {
    position: relative;
    border-bottom: 2px dashed $light-grey;
    padding: 0.5rem 3rem 0.5rem 3rem;
    font-size: 0.75rem;
    .date {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $light-grey;
      display: block;
      min-width: 30px;
      min-height: 36px;
      text-align: center;
      font-family: $headings-font-family;
      color: $dark;
      .day {
        display: block;
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1rem;
      }
      .num {
        display: block;
        font-size: 1.25rem;
        line-height: 1rem;
      }
    }
    .status {
      color: $primary;
      font-size: 1.5rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  li:last-child {
    border-bottom: none;
  }
}


.task-list-alt{
  list-style: none;
  margin: 0;
  padding: 0;
  color: $body-color;
  font-size: 0.875rem;
  text-align: left;
  li{
     border-bottom: 2px dashed $light-grey;
     .task{
      text-align: left;

     }
  }
  li:last-child{
    border-bottom: none;
  }
  li.important{
      background-color: $menu-bg;
      color:$white;
      a{
          background-color: $menu-bg;
          color:$white;

      }
      .icon-wrap{
          color:$white;
      }
  }
  .task-icon{
    font-size:1.25rem;
    padding:0 1rem;
  }
  li > a{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      flex-flow: row nowrap;
      justify-content: flex-start;
      transition: 0.3s;
      color: $dark;
      font-weight: 400;
      padding:1rem 0;
  }
  li > .flex-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      flex-flow: row nowrap !important;
      justify-content: flex-start;
  }
  li > a:hover{
      background-color:$light;
      text-decoration:none;
  }
  li{
    .task-title{
      display:block;
      font-weight:400;
     font-family: $headings-font-family;
     text-transform: uppercase;
     font-size:0.875rem;
    }
    .task{
      font-size:0.75rem;
      padding-right:50px;
    }
      img{
          margin-right:1rem;
      }
  }
  .icon-wrap{
      position: absolute;
      right:10px;
      top:10px;
      font-size:1.25rem;
      color:$primary;
  }


}