.speech-bubble{
    margin:1rem;
    padding:.5rem 1rem;
    position: relative;
    color: #333;
    font-weight:500;
}
.speech-bubble-left{
    background-color:rgba($green, 20%);
    border-radius: 1rem 1rem 1rem 0;
    margin-right:100px;
}
.speech-bubble-left::after{
    content: " ";
    position: absolute;
    left:0;
    bottom:-10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 0 0;
    border-color: rgba($green, 20%) transparent transparent transparent;
}
.speech-bubble-right{
    background-color: $light-grey;
    border-radius: 1rem 1rem 0 1rem;
    margin-left:100px;
}
.speech-bubble-right::after{
    content: " ";
    position: absolute;
    right:0;
    bottom:-10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 0;
    border-color: transparent $light-grey transparent transparent;
}