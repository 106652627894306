.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: $dark;
}
.heading-sm{
  font-size:.875rem;
}
h3.step-title, h3.sm-title{
  font-size:1rem;
  border-bottom:2px solid $menu-bg;
  text-align: center;
  padding-bottom:.5rem;
  font-weight: 500;
}
h3.sm-title-lineless{
  border-bottom:none;
}
h4.oversized{
  font-size:1rem;
}
b,strong{
  font-weight:700;
}