.content-title {
  border-bottom: 2px solid $white;
  text-align: center;
  position: relative;
  font-size: 1.125rem;
  .aside {
    font-size: 0.625rem;
    position: absolute;
    color: $body-color;
    bottom: 6px;
    text-transform: none;
    font-family: $font-family-base;
  }
  .aside-left {
    left: 0;
  }
  .aside-right {
    right: 0;
  }
}
.content-title.content-title-nu {
  border-bottom: none;
}
.bg-white {
  .content-title {
    border-color: $light;
  }
}
