.exercise-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: block;
    position: relative;
    background-color: $white;
    font-family: $headings-font-family;
    color: $dark;
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    cursor:pointer;
    .icon-wrap {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  li.selected {
    background-color: $yellow;
  }
}
.exercise-list-left{

  li {
    padding-left: 2rem;
    font-family: $font-family-sans-serif;
    .icon-wrap {
      position: absolute;
      left: 8px;
      right:auto;
      top: 6px;
      font-size: 18px;
      transform: translateY(0%);
    }
  }
  
}
//table-activity
.table-activity {
  max-width: 340px;
  margin: 0 auto;
  thead th {
    color: $dark;
    border-bottom: none;
    border-top: none;
  }
  td {
    line-height: 28px;
    padding: 0.4rem;
    border-top: none;
    color: $dark;
      span {
        width: 24px;
        height: 28px;
        display: inline-block;
      }
      .circle {
        line-height: 28px;
      }
  }
  .circle {
    height: 28px;
    width: 28px;
    font-size: 1rem;
    margin: 0;
    color: $white;
    line-height: 1;
  }

  .activity-transparent {
    opacity: 0.33;
  }
  
}

.card-month {
  padding: 0.5rem;
}

.exercise-level-q {
  background-color: $light;
  padding: 1rem;
  text-align: center;
  h4 {
    font-size: 1.5rem;
    color: $dark;
    font-family: "Open Sans", Arial;
    margin-bottom: 1rem;
    text-transform: none;
  }
  button {
    line-height: 1.2rem;
    min-width: 7rem;
  }
}


.svg-button-1 {
  height: 4rem;
  padding: 0.5rem 0;
  fill: #fff;
}

.svg-button-2 {
  height: 4rem;
  padding: 0.5rem 0;
  fill: #fff;
}