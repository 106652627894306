.theme-sb{
  .bs-datepicker-body table td.week span
  {
    color: $dark !important;
  }

  .bs-datepicker-body table td span.selected,
  .bs-datepicker-head,
  .bs-datepicker-head, .bs-datepicker button:active,
  .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*="select-"]:after,
  .bs-datepicker-body table td[class*="select-"] span:after,
  .bs-datepicker-body table td.active-week span:hover
  {
    background-color: $primary !important;
  }
  .bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span{

    color: rgba($dark, 0.3) !important;
    cursor: not-allowed;
  }

  .bs-datepicker-body table td span.is-today, .bs-datepicker-body table td.is-today span{
    background-color:rgba($primary, 0.1);
  }

}
