html {
  position: relative;
  min-height: 100%;
}
body.has-footer-nav {
  margin-bottom: 74px; /* Margin bottom by footer height */
}
.fh {
  min-height: 100vh;
}
.fh-nav {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-bottom:150px;
}
.fh-nav-subnav {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-bottom:150px;
}
.content-container {
  padding: 1rem;
  .row {
    margin-top: 1rem;
  }
}
.text-page-container {
  background-color: $white;
  padding: 0px 20px 20px 20px;
}

.bg-dark {
  color: $white;
  a {
    color: $white;
  }
  hr {
    border-color: rgba($white, 0.5);
  }
}
.bg-grey {
  background-color: $light-grey;
}
.text-menu-bg {
  color: $menu-bg;
}
.text-light-grey {
  color: $light-grey;
}
.divider {
  border-top-width: 2px;
}
.cursor, .pointer{
  cursor: pointer;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}

.container-main{
  padding:0;
}

// form addons
.inner-addon {
  position: relative;
}
.inner-addon .addon {
  position: absolute;
  padding: 10px;
  z-index: 20;
}
.inner-addon .addon.btn {
  padding: 5px;
  margin: 5px;
  text-align: center;
}
.left-addon .addon {
  left: 0px;
}
.right-addon .addon {
  right: 0px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}


a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.nmh{
  min-height: auto!important;
}
.icon-xl{
  font-size:3rem;
}

.btn-white {
  background-color: $white;
  color: $primary;
}

// media queries
@include media-breakpoint-up(md) {
  .container-main{
    padding:2rem;
  }
  .animation-container{
    overflow: hidden;
    position: relative;
  }
}


// media queries
@include media-breakpoint-up(md) {
  .content-wrap {
    margin: 1rem auto;
    background-color: $white;
    border-radius: $border-radius;
    min-height:100vh;
    padding:.5rem 1.5rem;
  }
  .content-wrap-np{
    padding:0;

  }
}

// media queries
@include media-breakpoint-down(md) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-spacer {
    padding-bottom: 1rem;
  }

  .inline-footer .text-right {
    text-align: left !important;
    margin-top: 0.5rem;
  }
}

.ovh{
  overflow: hidden;
}