.card {
  border: none;
}

.card-body {
  padding: 1.25rem 1rem;
}

.card-title {
  font-size: 1.125rem;
}
.card-title-u {
  border-bottom: 2px solid $light-grey;
}
.card-title-lc {
  text-transform: none;
  font-family: $font-family-base;
}
.card-form {
  .form-control {
    display: block;
    text-align: center;
    border: 2px solid $light-grey;
    font-size: 1.5rem;
    font-family: $headings-font-family;
    text-transform: uppercase;
    color: $dark;
  }
  label {
    font-family: $headings-font-family;
    font-weight: normal;
    text-transform: uppercase;
    color: $dark;
    margin-bottom: 0;
  }
  .text-middle {
    position: relative;
    label, input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  .label-lg {
    font-size: 1.125rem;
  }
  .instruction {
    font-size: 0.75rem;
  }
}
