
// Custom slider styles..
.custom-slider {
  position: relative;
  margin: 1rem auto 0 auto;
  width: 275px;
  .img-scale {
    width: 275px;
    position: absolute;
    top: 0px;
    left: 0;
  }
}
.custom-slider .ngx-slider .ngx-slider-bar {
  background: none;
  height: 32px;
  position: relative;
}

.custom-slider .ngx-slider .ngx-slider-selection {
  background: $primary;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 16px;
  height: 40px;
  top: auto; /* to remove the default positioning */
  bottom: 0;
  background-color: $primary;
  border-radius: 5px;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: $primary;
}

.custom-slider-v {
  position: relative;
  width: 350px;
  margin: 0px auto 0 auto;
  height: 275px;
  .img-scale {
    position: absolute;
    width: auto;
    top: 0px;
    left: 50%;
    margin-left: -20px;
    height: 275px;
  }
  .ngx-slider .ngx-slider-bar {
    background: none;
    width: 41px;
    position: relative;
  }

  .ngx-slider .ngx-slider-selection {
    background: $primary;
  }

  .ngx-slider .ngx-slider-pointer {
    height: 16px;
    width: 40px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: $primary;
    border-radius: 5px;
  }

  .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: $primary;
  }
}

// slider-scale
.slider-scale {
  display: flex;
  width: 271px;
  margin: 20px auto;
  align-items: center;
  position: relative;
  .icon-left {
    position: absolute;
    left: -35px;
    top: -18px;
    text-align: left;
    max-width: 100px;
  }
  .icon-right {
    position: absolute;
    right: -35px;
    top: -18px;
    text-align: right;
    max-width: 100px;
  }
  .icon-wrap {
    font-size: 1.5rem;
  }
  .scale-label {
    font-size: 0.625rem;
    display: block;
  }
  .scale-line-left {
    flex-grow: 1;
    position: relative;
    display: block;
    height: 2px;
    background-color: #cdd3dd;
  }
  .scale-line-right {
    flex-grow: 1;
    position: relative;
    display: block;
    height: 2px;
    background-color: #cdd3dd;
  }
  .scale-line-right:after {
    position: absolute;
    content: "";
    bottom: -3px;
    right: -4px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #cdd3dd;
  }

  .scale-line-left:after {
    position: absolute;
    content: "";
    top: -3px;
    left: -4px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #cdd3dd;
  }

  .circle {
    height: 24px;
    width: 24px;
    font-size: 1.125rem;
    line-height: 24px;
    position: absolute;
    z-index: 2;
  }
}
// vertical
.slider-scale-v {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  width:145px;
  height: 275px;
  margin: 20px auto;
  top: -20px;
}

.slider-scale-v {
  .icon-left {
    display: block;
    position: relative;
    text-align: right;
    max-width: 170px;
    line-height: 1;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;
    .icon-wrap{
      margin-left:5px;
      display:block;
      float:left;
    }
  }
  .icon-right {
    display: block;
    position: relative;
    text-align: right;
    max-width: 170px;
    line-height: 1;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;
    .icon-wrap{
      margin-left:5px;
      display:block;
      float:left;
    }
  }
  .scale-line-left,
  .scale-line-right {
    height: 135px;
    width: 2px;
    display: block;
    flex-grow: unset;
    margin-right:10px;
  }
  .scale-line-left{
    margin-top:10px;
  }
  .scale-line-right{
    margin-bottom:10px;
  }

  .scale-line-left:after{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 4px 3px;
    border-color: transparent transparent #cdd3dd transparent;
    top:-4px;
    left:-2px;
  }
  .scale-line-right:after{
    width: 0;
height: 0;
border-style: solid;
border-width: 4px 3px 0 3px;
border-color: #cdd3dd transparent transparent transparent;
    bottom:-4px;
    left:-2px;
  }
  .scale-label {
    display:block;
    float:left;
    max-width: 100px;
  }
}
