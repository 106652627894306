.navbar-subnav {
  background-color: $menu-bg;
  min-height: 40px;
  .navbar-brand {
    color: $white;
    margin: 0;
    padding: 0;
  }
  .btn-nav-menu {
    font-size: 0.9rem;
  }
}
.btn-nav-menu {
  border: none;
  background-color: transparent;
  color: $white;
  font-size: 1rem;
  font-family: $headings-font-family;
}
.navbar-light {
  .btn-nav-menu {
    color: $dark;
  }
}
.btn-nav-menu-sm {
  font-size: 0.7rem;
  text-transform: uppercase;
  .icon-wrap {
    font-size: 0.7rem;
  }
}
.navbar-subnav-alt {
  .indicators {
    color: $dark;
    top: 5px;
  }
  .btn-nav-menu-sm {
    span {
      position: relative;
      top: -1px;
    }
  }
}
// media queries
@include media-breakpoint-up(lg) {
  .navbar-subnav {
    .btn-nav-menu {
      visibility:hidden;
    }
  }
}
