.record-table {
  width: 100%;
  border-spacing: 0 0.5rem;
  border-collapse: separate;
  th {
    text-align: center;
    color: $dark;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: $headings-font-family;
    text-transform: uppercase;
  }
  td {
    background-color: $white;
    padding: 0.5rem;
    text-align: center;
    .circle {
      height: 30px;
      width: 30px;
      font-size: 1.125rem;
      line-height: 27px;
    }
    .circle-o {
      background-color: transparent;
      border: 2px solid $light-grey;
    }
    .no-status .goal-status .icon-wrap {
      color: $light-grey;
    }
    .goal-status-container {
      top: auto;
      line-height: auto;
      margin: auto 0.25rem;

      .goal-status .icon-wrap {
        font-size: 1.5rem;
      }
      .goal-status span {
        position: absolute;
        font-size: 0.625rem;
        color: $white;
        background-color: $semi-dark;
        display: inline-block;
        border-radius: 50%;
        line-height: 12px;
        height: 12px;
        width: 12px;
        bottom: 0px;
        right: -4px;
        text-align: center;
      }
    }
  }
  td.td-date {
    background-color: $light-grey;
    color: $dark;
    font-family: $headings-font-family;
    span {
      display: block;
      font-size: 1.5rem;
      text-transform: uppercase;
      line-height: 1.5rem;
    }
    span.month {
      font-size: 1.125rem;
    }
  }
  td.td-date.current {
    background-color: $yellow;
    color: $dark;
  }
  td .r-title {
    display: block;
    font-family: $headings-font-family;
    text-transform: uppercase;
    text-align: left;
  }
  td .r-total {
    display: block;
    text-align: left;
    font-size: 0.75rem;
  }
  td .r-goal {
    font-family: $font-family-base;
  }
  .r-goal-icon {
    font-size: 1.875rem;
  }
}
