.action-plan-list{
    list-style:none;
    margin:1rem 0;
    padding:0;
    li{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: .75rem;
        padding:.5rem;
        border-bottom:2px dashed $light-grey;
        .num{
            display: block;
            min-width:30px;
            height:30px;
            line-height: 30px;
            text-align:center;
            display: inline-block;
            margin-right: .75rem;
            background-color:$primary;
            color:$white;
            border-radius: 50%;
            margin-top:0px;
        }
        a{
            .icon-wrap{
                position: absolute;
                right:1rem;
                
            }
        }
    }

    li:last-child{
        border:none;
    }
}