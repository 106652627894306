.side-nav {
  background-color: $menu-bg;
  .navbar-brand {
    color: $white;
    margin: 0;
    padding: 0;
  }
  .accordion {
    background-color: $menu-bg;
    color: $white;
    padding: 1rem 0.5rem 0 0.5rem;

    .card {
      background-color: $menu-bg;
      margin: 0.5rem 0;
    }
    .card:first-child{
      margin-top:0;
    }
    .card-body {
      padding: 0;
    }
    .nav-link,
    .btn-link {
      color: $white;
    }
    .nav-link.disabled {
      color: darken($white, 30);
    }
    .card-header {
      background: none;
      padding: 0;
      border: none;
      .btn-link {
        display: block;
        width: 100%;
        background-color: $white;
        color: $dark;
        font-size: 1rem;
        text-align: left;
        padding: 0.33rem 0.5rem;
      }

      .btn-link.collapsed {
        background-color: $semi-dark;
        color: $white;
      }
      .btn-link.active {
        color: $semi-dark;
        background-color: $white;
      }

      .btn-link:focus {
        text-decoration: none;
      }
    }

    .side-nav-menu {
      .nav-link {
        font-family: $headings-font-family;
        font-size: 0.875rem;
        text-transform: uppercase;
        position: relative;
        padding: 0.5rem 1rem 0.5rem 1.75rem;
        color: rgba($white, 0.75);
      }

      .nav-link::before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f356";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .nav-link.active {
        color: $white;
      }
      .nav-link.active::before {
        font-weight: 900;
      }
    }
  }
}
// media queries
@include media-breakpoint-up(md) {
  .side-nav {
    .accordion {
      padding: 0rem
    }
  }
}
