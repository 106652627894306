.footer{
    a{
        color:#333;
    }
    border-top:2px solid $white;
    font-size:0.75rem;
}
.inline-footer{
    font-size:0.75rem;

}