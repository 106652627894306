.top-0 {
  top: 0;
}
.mt-0 {
  margin-top: 0;
}
.pt-0 {
  padding-top: 0;
}
.stick-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}
.stick-bottom.stick-bottom-with-nav {
  bottom: 60px;
}
@include media-breakpoint-up(lg) {
  .relative-md{
    position: relative !important;
  }
}

