.jumbotron {
  border: 1px solid $white;
  .lead {
    font-size: 0.875rem;
    margin-bottom: 0;
    font-weight: 600;
    color: $dark;
  }
  .circle {
    height: 36px;
    width: 36px;
    font-size: 1.125rem;
    line-height: 36px;
  }
  a {
    font-size: 0.625rem;
  }
}
.jumbotron-slim {
  padding: 0.5rem 0;
  line-height: 1.2;
  .status {
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
    color: $dark;
  }
  .status-icon {
    font-size: 1.875rem;
  }
  .total {
    font-size: 1.875rem;
    font-family: $headings-font-family;
    color: $dark;
    font-weight: 400;
  }
}
.jumbotron-slim.active {
  background-color: $white;
}

.jumbotron-slim.muted {
  color: #666;
  .status,
  .status-icon,
  .total {
    color: #666;
  }
}
