.progress-table {
  width: 100%;
  border-spacing: 5px;
  border-collapse: separate;
  th {
    text-align: center;
    color: $dark;
    padding: 0;
    font-size: 1.25rem;
  }
  td {
    background-color: $white;
    padding: 0.5rem;
    text-align: center;
    .circle {
      height: 24px;
      width: 24px;
      font-size: 1.125rem;
      line-height: 24px;
    }
    .no-status .goal-status .icon-wrap {
      color: $light-grey;
    }
    .goal-status-container {
      top: auto;
      line-height: auto;
      margin: auto 0.25rem;

      .goal-status .icon-wrap {
        font-size: 1.5rem;
      }
      .goal-status span.total {
        position: absolute;
        font-size: 0.625rem;
        color: $white;
        background-color: $semi-dark;
        display: inline-block;
        border-radius: 50%;
        line-height: 12px;
        height: 12px;
        width: 12px;
        bottom: 0px;
        right: -4px;
        text-align: center;
      }
    }
  }
  td.td-date {
    background-color: $dark;
    color: $white;
    font-family: $headings-font-family;
    span {
      display: block;
      font-size: 1.25rem;
      text-transform: uppercase;
      line-height: 1rem;
    }
    span.day {
      font-size: 0.75rem;
    }
  }
  td.td-date.current {
    background-color: $yellow;
    color: $dark;
  }
}
.card-body{
  .progress-table{
    td {
      background-color:$light;
    }
    td.td-date {
      background-color: $dark;
      color: $white;
      padding: 0.4rem 0.25rem 0.5rem 0.25rem;
    }
  }

  .no-status .goal-status .icon-wrap{
    color: rgb(87,78,113, 0.5);
  }
  td .no-status .goal-status .icon-wrap{
    color: rgb(87,78,113, 0.5);

  }
  td .bg-grey{
    background-color: rgb(87,78,113, 0.5);

  }
}