.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  background-color: $light-grey;
  border-color: $white;
  color: $dark;
  font-family: $headings-font-family;
  text-transform: uppercase;
  font-weight: 400;
  border-top: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: $white;
}
.card-body {
  .nav-tabs {
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
  }
}
