.extra-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0 0 0.5rem 0;
    position: relative;
    overflow: hidden;
    background-color: $primary;
    color: $white;
    img {
      width: 100px;
    }
    span {
      font-family: $headings-font-family;
      display: block;
      left: 110px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}